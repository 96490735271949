<template>
  <div class="edit-album-container">
    <h1>Edit Album</h1>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <form v-else @submit.prevent="updateAlbum" class="edit-album-form">
      <div class="form-group">
        <label for="artist">Artist</label>
        <input type="text" v-model="album.artist" id="artist" required>
      </div>
      <div class="form-group">
        <label for="title">Title</label>
        <input type="text" v-model="album.title" id="title" required>
      </div>
      <div class="form-group">
        <label for="submitted_by">Submitted By</label>
        <select v-model="album.submitted_by" id="submitted_by">
          <option v-for="name in ['Arno', 'Tyrone', 'Lucas', 'Thomas', 'Wouter']" :key="name" :value="name">
            {{ name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="img">Image URL</label>
        <input type="text" v-model="album.img" id="img" required>
      </div>
      <div class="form-group">
        <label for="reviewed_at">Reviewed At</label>
        <input type="text" v-model="album.reviewed_at" id="reviewed_at" required>
      </div>
      <div class="form-group">
        <label for="spotify_id">Spotify ID</label>
        <input type="text" v-model="album.spotify_id" id="spotify_id" required>
      </div>
      <div class="form-group">
        <label for="spotify_url">Spotify URL</label>
        <input type="text" v-model="album.spotify_url" id="spotify_url" required>
      </div>
      <div class="form-group">
        <label for="veto">Veto</label>
        <input type="checkbox" v-model="album.veto" id="veto">
      </div>
      <div class="form-group" v-if="album.veto">
        <label for="veto_by">Vetoed By</label>
        <input type="text" v-model="album.veto_by" id="veto_by">
      </div>
      <div class="form-group tier-group" v-for="(tier, reviewer) in album.individual_tiers" :key="reviewer">
        <label :for="'individual_tier_' + reviewer">{{ reviewer }}'s Tier</label>
        <div class="tier-buttons">
          <button
            type="button"
            v-for="option in tiers"
            :key="option"
            :class="['tier-button', { active: album.individual_tiers[reviewer] === option }]"
            @click="album.individual_tiers[reviewer] = option"
          >
            {{ option }}
          </button>
        </div>
      </div>
      <div class="form-group">
        <button type="button" @click="calculateScore" class="score-button">
          Calculate Score
        </button>
      </div>
      <div v-if="scoreResult" class="score-result">
        <p><strong>Average Score:</strong> {{ scoreResult.averageScore ?? 'N/A' }}</p>
        <p><strong>Suggested Tier:</strong> {{ scoreResult.suggestedTier ?? 'No suggestion' }}</p>
      </div>
      <div class="form-group tier-group">
        <label for="tier">Overall Tier</label>
        <div class="tier-buttons">
          <button
            type="button"
            v-for="tier in tiers"
            :key="tier"
            :class="['tier-button', { active: album.tier === tier }]"
            @click="album.tier = tier"
          >
            {{ tier }}
          </button>
        </div>
      </div>
      <div class="form-group password-group">
        <label for="password">Password</label>
        <input type="password" v-model="password" id="password" required>
      </div>
      <button type="submit" class="save-button">Save Changes</button>
    </form>
  </div>
</template>

<script>
import apiService from '../apiService';
import scoreService from '../services/scoreService';

export default {
  name: 'EditAlbum',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      album: null,
      password: '',
      loading: true,
      error: null,
      tiers: ["S", "A", "B", "C", "D", "E", "Tiering Herrie", "?", "Now Listening To", "Not rated yet"],
      scoreResult: null
    };
  },
  async created() {
    try {
      const response = await apiService.getAlbum(this.id);
      this.album = response;
      this.loading = false;
    } catch (error) {
      console.error('Error fetching album:', error);
      this.error = 'Album not found';
      this.loading = false;
    }
  },
  methods: {
    async calculateScore() {
      try {
        const tiers = Object.values(this.album.individual_tiers); // Collect individual tiers
        this.scoreResult = await scoreService.calculateScore(tiers); // Use the scoring service
      } catch (error) {
        console.error('Error calculating score:', error);
        alert('Failed to calculate score. Please try again.');
      }
    },
    async updateAlbum() {
      try {
        await apiService.updateAlbum(this.id, this.album, this.password);
        alert('Album updated successfully!');
        this.$router.push('/');
      } catch (error) {
        console.error('Error updating album:', error);
        alert('Failed to update album. Please try again.');
      }
    },
    
  }
};
</script>

<style scoped>
.edit-album-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #292929;
  color: #f3f3f3;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
}

.loading, .error {
  text-align: center;
  margin-bottom: 20px;
}

.edit-album-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="password"],
.form-group select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f3f3f3;
  color: #000;
}

.form-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.tier-group {
  padding-top: 10px;
  margin-top: 10px;
}

.password-group {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
}

.save-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #00ac4e;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #276584;
}

.score-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4c8eaf;
  color: #fff;
  cursor: pointer;
}

.score-button:hover {
  background-color: #276584;
}

.score-result {
  margin-top: 15px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
}

.tier-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.tier-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tier-button.active {
  background-color: #4c8eaf; /* Highlight selected tier */
  color: #fff;
}

.tier-button:hover {
  background-color: #276584;
}

.tier-buttons .tier-button:nth-child(odd) {
}

select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f3f3f3;
  color: #000;
  width: 100%;
  margin-top: 5px;
}
</style>
