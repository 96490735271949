import apiService from '../apiService';

const calculateScore = async (tiers) => {
  if (!Array.isArray(tiers) || tiers.length === 0) {
    throw new Error('Invalid input: tiers must be a non-empty array.');
  }

  try {
    const response = await apiService.calculateScore(tiers);
    return response; // Returns { averageScore, suggestedTier }
  } catch (error) {
    console.error('Error calculating score:', error);
    throw error;
  }
};

export default {
  calculateScore,
};