<template>
  <div>
    <div class="filters">
  <!-- Search Bar -->
  <div class="search-container">
    <input type="text" v-model="searchTerm" placeholder="Search by artist or title...">
  </div>

  <!-- Filter Buttons -->
  <div class="filter-buttons">
    <!-- Clear Filters Button -->
    <button class="clear-filters" @click="setTierGrouping('clear')">Clear filters</button>
    
    <!-- Toggle Buttons -->
    <div class="filter-toggle">
      <button 
        :class="{ active: filterType === 'ratings' }" 
        @click="filterType = 'ratings'">
        Ratings by
      </button>
      <button 
        :class="{ active: filterType === 'submitted' }" 
        @click="filterType = 'submitted'">
        Submitted by
      </button>
    </div>
    <!-- Name Buttons -->
    <div v-if="filterType === 'ratings'" class="filter-names">
      <button 
        v-for="name in individualTierNames" 
        :key="`rating-${name}`" 
        :class="{ active: currentTierGrouping === name }"
        @click="setTierGrouping(name)">
        {{ capitalize(name) }}
      </button>
    </div>
    <div v-if="filterType === 'submitted'" class="filter-names">
      <button 
        v-for="name in individualTierNames" 
        :key="`submitter-${name}`" 
        :class="{ active: selectedSubmitter === name }"
        @click="setSubmitterFilter(name)">
        {{ capitalize(name) }}
      </button>
    </div>
  </div>
</div>
      <div class="selected-tier-filter">
        <strong>{{ currentViewingText }}</strong>
      </div> 
    <div v-for="[tier, albums] in filteredAlbumsByTier" :key="tier" :class="['tier-container', tierClass(tier)]">
      <div class="tier-title">{{ tier }}</div>
      <div class="albums-scroll-wrapper">
        <div class="albums-scroll-container" ref="albumsContainerRef">
          <albumCover v-for="album in albums" :key="album.id" :album="album" :veto="album.veto === true" @albumClicked="handleAlbumClicked"></albumCover>
        </div>
      </div>
    </div>
    <albumDetailsModal v-if="selectedAlbum" :album="selectedAlbum" :veto="selectedAlbum.veto" :veto_by="selectedAlbum.veto_by" @close="selectedAlbum = null" />
  </div>
</template>

<script setup>
import { useTierRow } from '../composables/useTierRow';
import albumCover from './albumCover.vue';
import albumDetailsModal from './albumDetailsModal.vue';
import { capitalize } from '../utils/capitalize';

const {
  currentViewingText,
  currentTierGrouping,
  filteredAlbumsByTier,
  individualTierNames,
  searchTerm,
  selectedAlbum,
  albumsContainerRef,
  handleAlbumClicked,
  tierClass,
  setTierGrouping,
  setSubmitterFilter,
  filterType, // Use filterType from composable
} = useTierRow();

</script>


<style scoped>
  .tier-title {
    font-size: 20px;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    justify-content: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: rgba(0, 0, 0, 0.473);
  }
  .tier-row {
    display: flex;
    align-items: stretch;
    text-align: left;
    background-color: rgb(41, 41, 41);
  }

  .filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.filter-toggle {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin: 0px px;
}

.filter-toggle button {
  background: #606060;
}

.filter-names {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  justify-content: center;
  gap: 5px;
  overflow-x: auto; /* Allow horizontal scrolling if space is too tight */
}

.filter-names button {
  font-size: 14px; /* Adjust font size for smaller screens */
  min-width: 60px; /* Ensure buttons are wide enough for readability */
}

.clear-filters {
  background: #971515;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s;
}

.clear-filters:hover {
  background: #ff4040;
}

button {
  background: black;
  color: white;
  padding: 10px 12px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background 0.2s;
}

button.active {
  background: #007bff;
  color: white;
}

button:hover {
  background: #444;
}


.search-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-container input {
  width: 50%; /* Default width */
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

  .selected-tier-filter {
    display: flex;
    font-size: 24px; /* Larger text size */
    text-align: left;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    flex-basis: 100%;
  }

  .albums-scroll-wrapper {
    position: relative;
    overflow: hidden; /* Ensures the gradient doesn't extend the scroll area */
    margin-bottom: 15px;
  }

  .albums-scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 0px;
  }

  .albums-scroll-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
  }

  /* tier-specific styles */
  .tier-s {
    background-color: #E63946
  }

  .tier-a {
    background-color: #F4A261;
  }

  .tier-b {
    background-color: #F9C74F;
  }

  .tier-c {
    background-color: #2A9D8F;
  }

  .tier-d {
    background-color: #457B9D;
  }

  .tier-e {
    background-color:#9C89B8;
  }

  .tier-tiering {
    background-color: #FAD2E1;
  }

  .tier-not {
    background-color: black;
  }

  .tier-now {
    background-color:  #00ac4e; 
  }



  /* Adjustments for mobile */
  @media (max-width: 600px) { /* Adjust this value based on your needs */

    .filters {
      flex-direction: column;
      align-items: stretch;
      margin-top: 5px;
    }

    .filter-names {
      gap: 5;
      justify-content: left;
    }

    .filter-buttons {
      justify-content: center;
      flex-wrap: wrap; /* Allow the buttons to wrap */
      justify-content: center; /* Center-align the buttons for a more balanced look */
    }

    .albums-scroll-container {
      gap: 0px; /* Reduce gap on smaller screens */
    }

    /* Ensure the selected-tier-filter text scales down on smaller screens */
    .selected-tier-filter {
      font-size: 18px; /* Smaller font size for mobile */
      justify-content: center; /* Center the text for better alignment */
      padding: 5px 0; /* Adjust padding */
    }

    .selected-tier-filter {
      font-size: 18px;
      justify-content: center;
      text-align: center;
    }

    .search-container {
      margin-right: 5px;
      margin-bottom: 10px;
    }

    .search-container input {
      width: 100%; /* Full width on mobile */
      padding: 15px 20px; /* Increase padding for mobile */
      font-size: 16px;    /* Adjust font size for mobile */
    }
  }
</style>
