/* global gtag */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import googleAnalytics from './plugins/googleAnalytics';

const app = createApp(App);

app.use(router);
app.use(googleAnalytics);

// Track page views after every route change
router.afterEach((to) => {
  if (process.env.VUE_APP_ENV === 'production') {
    if (typeof gtag === 'function') {
      gtag('config', process.env.VUE_APP_GA_ID, { page_path: to.fullPath });
    }
  }
});

app.mount('#app');