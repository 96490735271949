/* global dataLayer */
export default {
    install(app) {
      if (process.env.VUE_APP_ENV !== 'production') {
        console.log('Google Analytics is disabled in development.');
        return;
      }
  
      const gtagScript = document.createElement('script');
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GA_ID}`;
      gtagScript.async = true;
      document.head.appendChild(gtagScript);
  
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', process.env.VUE_APP_GA_ID);
  
      app.config.globalProperties.$gtag = function (...args) {
        if (typeof gtag === 'function') {
          gtag(...args);
        }
      };
    }
  };