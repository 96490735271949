<template>
    <div class="album" @click="emitAlbumClicked">
        <img :src="album.img" alt="Album cover">
        <p class="artist">{{ album.artist }}</p> 
        <p class="title">{{ album.title }}</p>
    </div>
</template>

<script>
export default {   
    props: ['album', 'veto'], 
    name: 'albumCover',
    methods: {
        emitAlbumClicked() {
            this.$emit('albumClicked', this.album);
        }
    }
}

</script>

<style scoped>
   .album {
        position: relative; /* Add this line */
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 150px;
        padding: 20px 15px;
    }

    .album:hover{
        cursor: pointer;
        background: rgba(255, 255, 255, 0.2);
    }
    .album p {
        align-self: stretch;
        margin: 0;
        font-size: 12px;
        
    }
    .album .artist {
        font-weight: bold;
    }
    .album img {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 150px;
    }

    .veto-warning-icon {
        position: absolute;
        top: 0; /* Adjust as needed */
        right: 0; /* Adjust as needed */
        font-size: 24px; /* Adjust as needed */
    }
    
</style>